const apiKey = process.env.POWERUP_KEY;

export async function getBoardBackground(t, boardId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/boards/${boardId}?fields=prefs&key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.prefs) {
          return {
            url: data.prefs.backgroundImage,
            color: data.prefs.backgroundColor,
            topColor: data.prefs.backgroundTopColor,
            brightness: data.prefs.backgroundBrightness,
          };
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch Trello board background:", error);
        return null;
      });
  }
}

export async function getCard(t, cardId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/cards/${cardId}?key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Failed to fetch Trello card:", error);
        return null;
      });
  }
}

export async function postCardToTrello(t, cardName, selectedListId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    const url = `https://api.trello.com/1/cards?name=${encodeURIComponent(
      cardName
    )}&idList=${selectedListId}&key=${apiKey}&token=${token}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error posting card to Trello:", error);
    }
  }
}

export async function getMembersBoards(t, memberId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/members/${memberId}/boards?key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // Filter out closed boards
          const openBoards = data.filter((board) => !board.closed);
          return openBoards;
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch members boards:", error);
        return null;
      });
  }
}

export async function getOrganizationsBoards(t, orgId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/organizations/${orgId}/boards?key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // Filter out closed boards
          const openBoards = data.filter((board) => !board.closed);
          return openBoards;
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch organizations boards:", error);
        return null;
      });
  }
}

export async function getBoardLists(t, boardId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/boards/${boardId}/lists?key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // Filter out closed boards
          const openLists = data.filter((list) => !list.closed);
          return openLists;
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch organizations boards:", error);
        return null;
      });
  }
}
